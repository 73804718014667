import * as JsPDF from "jspdf";
import "jspdf-autotable";
import humps from "humps";
import pluralize from "pluralize";

import { roundDigits } from "@ROM/App/utils/format";
import { PENDING, IN_PROGRESS, SHIPPED, INVOICED, DELIVERED } from "@ROM/Deliveries/constants";
import { buildHeader, buildAdresses } from "@ROM/App/utils/pdf";
import { canEditDelivery, canUpdateDeliveryShipping } from "@ROM/Deliveries/permissions";
import {
  isCompleted as isOrderCompleted,
  isCancelled as isOrderCancelled,
  isInvoiced as isOrderInvoiced,
} from "@ROM/Orders/utils";

export const createDeliveryPdf = async (deliveryNumber, deliveryItems, order, company, orderIncluded, print = false) => {
  const addWaterMark = (pdf) => {
    const totalPages = pdf.internal.getNumberOfPages();
    pdf.setFontSize(30);
    pdf.setTextColor(229, 229, 229);
    pdf.setFontType("bold");

    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.text(62, 180, "NOT AN INVOICE");
    }

    return pdf;
  };

  const { attributes } = order;

  const customer = orderIncluded.find((incl) => incl.type === "customer");
  const billingAddress = orderIncluded.find((incl) => incl.type === "billingAddress");
  const shippingAddress =
    attributes.shippingAddressId === attributes.billingAddressId
      ? billingAddress
      : orderIncluded.find((incl) => incl.type === "shippingAddress");

  const deliveryItemsBody = deliveryItems.map((item) => {
    const {
      attributes: { productName, quantity, packageUnit },
    } = item;

    return [productName, pluralize(packageUnit || "", quantity, true)];
  });

  let pdf = new JsPDF("p", "mm", "a4");
  pdf.setFont("helvetica");
  pdf.rect(6, 6, 196, 280);

  const currentDatetime = new Date();
  const formattedDate = `${currentDatetime.getMonth() + 1}/${currentDatetime.getDate()}/${currentDatetime.getFullYear()}`;

  pdf.setFontSize(9);

  let lastY = await buildHeader(pdf, "Delivery Document", company, customer, attributes, formattedDate);
  pdf.text(`Delivery #: ${deliveryNumber || ""}`, 195, (lastY += 5), { align: "right" });

  lastY = await buildAdresses(pdf, billingAddress, shippingAddress);

  pdf.autoTable({
    theme: "striped",
    styles: { cellPadding: 1, lineWidth: 0.1 },
    headStyles: { halign: "left", fillColor: [62, 62, 62], textColor: [255, 255, 255], fontSize: 7 },
    bodyStyles: { fontSize: 7, textColor: [0, 0, 0], halign: "left" },
    columnStyles: {
      0: { cellWidth: 150 },
      1: { halign: "left" },
    },
    startY: lastY + 5,
    head: [["DESCRIPTION", "QTY"]],
    body: deliveryItemsBody,
  });

  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const totalQuantity = deliveryItems.map((deliveryItem) => deliveryItem.attributes.quantity).reduce(reducer, 0);

  pdf.autoTable({
    theme: "grid",
    styles: {
      fontSize: 7,
      textColor: [0, 0, 0],
      fontStyle: "bold",
      halign: "center",
      cellPadding: 1,
    },
    columnStyles: {
      0: { fillColor: [62, 62, 62], textColor: [255, 255, 255], cellWidth: 150, halign: "center" },
      1: { halign: "left" },
    },
    startY: pdf.autoTable.previous.finalY,
    body: [["TOTAL", totalQuantity]],
  });

  const pageCount = pdf.internal.getNumberOfPages();
  pdf.setPage(pageCount);

  pdf.setFontSize(12);

  pdf.text("Signature X", 15, 280);
  pdf.line(38, 280, 130, 280);

  pdf.text("Date", 135, 280);
  pdf.line(145, 280, 190, 280);

  pdf.setFontSize(9);
  pdf.text("THANK YOU FOR YOUR BUSINESS", 80, 292);
  pdf = addWaterMark(pdf);

  if (print) {
    pdf.autoPrint();
    // This won't work if the user has an ad blocker
    window.open(pdf.output("bloburl"));
  } else {
    pdf.save(`Delivery Document - ${deliveryNumber}.pdf`);
  }
};

export const createAddressPdf = async (order, orderIncluded, print = false) => {
  const { attributes } = order;

  const getAddress = (type) => orderIncluded.find((incl) => incl.type === type);
  const billingAddress = getAddress("billingAddress");
  const shippingAddress = attributes.shippingAddressId === attributes.billingAddressId
    ? billingAddress
    : getAddress("shippingAddress");
    
  const pdf = new JsPDF("l", "in", "letter");
  pdf.setFont("helvetica");

  // Letter size dimensions in inches
  const pageWidth = 11;
  const pageHeight = 8.5;
  const margin = 0.5;
  const addressLabel = {
    x: margin,
    y: margin,
    width: pageWidth - 2 * margin,
    height: pageHeight - 2 * margin
  };

  const borderWidth = 0.05;
  pdf.setLineWidth(borderWidth);

  pdf.setDrawColor(0, 0, 0);
  pdf.rect(addressLabel.x, addressLabel.y, addressLabel.width, addressLabel.height);

  const shippingAddressText = [
    attributes.shippingCustomerCompanyName,
    attributes.shippingAddress1,
    attributes.shippingAddress2,
    `${attributes.shippingCity}, ${attributes.shippingState} ${attributes.shippingZip}`,
    attributes.shippingCountryName,
    attributes.shippingPhone,
  ].filter(Boolean);

  const fontSize = 48;
  const lineHeight = fontSize * 1.3 / 70;

  pdf.setFontSize(fontSize);

  const wrapText = (text, maxWidth) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    words.forEach(word => {
      const testLine = currentLine + (currentLine ? ' ' : '') + word;
      const testWidth = pdf.getStringUnitWidth(testLine) * fontSize / 72;

      if (testWidth > maxWidth) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };

  const maxWidth = addressLabel.width - 2 * margin;
  let wrappedLines = [];

  shippingAddressText.forEach(line => {
    wrappedLines = wrappedLines.concat(wrapText(line, maxWidth));
  });

  const totalTextHeight = wrappedLines.length * lineHeight;
  let startY = addressLabel.y + (addressLabel.height - totalTextHeight) / 2;

  wrappedLines.forEach(line => {
    const lineWidth = pdf.getStringUnitWidth(line) * fontSize / 72;
    const x = addressLabel.x + (addressLabel.width - lineWidth) / 2;
    pdf.text(line, x, startY);
    startY += lineHeight;
  });

  if (print) {
    pdf.autoPrint();
    window.open(pdf.output("bloburl"));
  } else {
    pdf.save(`ShippingAddress.pdf`);
  }
};



export const isPending = (delivery) => delivery?.attributes?.status === PENDING;

export const isInProgress = (delivery) => humps.camelize(delivery?.attributes?.status) === IN_PROGRESS;

export const isShipped = (delivery) => delivery?.attributes?.status === SHIPPED;

export const isInvoiced = (delivery) => delivery?.attributes?.status === INVOICED;

export const isDelivered = (delivery) => delivery?.attributes?.status === DELIVERED;

export const showEditDeliveryButton = (order, delivery, user) => {
  const customerId = order.relationships.customer.data.id;

  return (
    !isOrderCompleted(order) &&
    !isOrderInvoiced(order) &&
    !isOrderCancelled(order) &&
    !isShipped(delivery) &&
    !isDelivered(delivery) &&
    canEditDelivery(user, customerId)
  );
};

export const showUpdateDeliveryShipping = (user, order) =>
  !isOrderCancelled(order) && canUpdateDeliveryShipping(user, order.attributes.customerId);

/**
 * Calculates items quantities reservations for an order items.
 * Returns
 * - remaining: a map from order item's id to the quantity of unassigned
 *   items.
 * - selected: a map that for each delivery of the order
 *   has a map of order item's id to the quantity of items reserved of that
 *   item in that delivery.
 */
export const calculateQuantities = (orderItems, deliveries, deliveryItems) => {
  const reducer = (orderItemId) => (acc, deliveryItem) => {
    if (deliveryItem.attributes.orderItemId.toString() === orderItemId) {
      return acc - deliveryItem.attributes.quantity;
    }
    return acc;
  };

  const remaining = orderItems.reduce(
    (acc, { id, attributes }) => ({
      ...acc,
      [id]: roundDigits(deliveryItems.reduce(reducer(id), attributes.quantity), 2),
    }),
    {}
  );

  const selected = deliveries.reduce((acc, delivery) => ({ ...acc, [delivery.id]: {} }), {});

  deliveryItems.forEach(({ attributes: { deliveryId, orderItemId, quantity } }) => {
    selected[deliveryId][orderItemId] = quantity;
  });

  return { remaining, selected };
};

export const deliveryItemsWeight = (deliveryItems) =>
  deliveryItems.reduce((acc, { attributes }) => acc + attributes.productPackageWeight * attributes.quantity, 0);

export const getStatusText = (status) => {
  switch (humps.camelize(status)) {
    case PENDING:
      return "Pending";
    case IN_PROGRESS:
      return "In progress";
    case SHIPPED:
      return "Shipped";
    case DELIVERED:
      return "Delivered";
    default:
      return status;
  }
};

export const getBackgroundColorByStatus = (status) => {
  switch (humps.camelize(status)) {
    case PENDING:
      return "#e9eaec";
    case IN_PROGRESS:
      return "#fff7de";
    case SHIPPED:
      return "#e8faec";
    case DELIVERED:
      return "#e8faec";
    default:
      return "#fff";
  }
};
