import update from "immutability-helper";
import { handle } from "redux-pack";
import { CREATE_DELIVERY_ITEM, UPDATE_DELIVERY_ITEM, DESTROY_DELIVERY_ITEM } from "@ROM/Deliveries/DeliveryItems/actions";
import { SHIP, IN_PROGRESS, DELIVER, INVOICE } from "@ROM/DeliveryStatuses/actions";
import { UPDATE as UPDATE_BILL_OF_LADING } from "@ROM/BillOfLadings/actions";
import { LIST, DESTROY, FIND, UPDATE, UPDATE_SHIPPING, CREATE, SET_PAGE } from "./actions";

const initialState = {
  loading: false,
  all: {
    items: [],
    included: [],
  },
  current: {
    delivery: null,
    included: [],
  },
  pagination: {
    currentPage: 1,
    perPage: null,
    totalPages: null,
    totalRecords: null,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const { data: json } = payload || {};
  switch (type) {
    case SET_PAGE:
      return update(state, {
        pagination: {
          currentPage: { $set: payload.page },
        },
      });

    case LIST:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, {
            loading: { $set: true },
          });
        },

        finish: (prevState) => {
          return update(prevState, {
            loading: { $set: false },
          });
        },

        success: (prevState) => {
          return update(prevState, {
            all: {
              items: { $set: json.data },
              included: { $set: json.included },
            },
            pagination: { $set: json.meta.pagination },
          });
        },
      });

    case FIND:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            current: { delivery: { $set: json.data }, included: { $set: json.included } },
          });
        },
      });

    case UPDATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const deliveryId = json.data.id;
          const index = prevState.all.items.findIndex((item) => item.id === deliveryId);

          return update(prevState, {
            current: { delivery: { $set: json.data }, included: { $set: json.included } },
            all: {
              items: {
                [index]: {
                  attributes: { $set: json.data.attributes },
                  relationships: { $set: json.data.relationships },
                },
              },
            },
          });
        },
      });

    case UPDATE_SHIPPING:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const deliveryIndex = prevState.all.items.findIndex((item) => item.id === json.data.id);
          const shipping = json.included.find((item) => item.type === "deliveryShipping");
          let shippingIndex = prevState.all.included.findIndex(
            (item) => item.type === "deliveryShipping" && item.id === shipping.id
          );
          if (shippingIndex === -1) shippingIndex = prevState.all.included.length;

          return update(prevState, {
            current: { included: { $set: json.included } },
            all: {
              items: {
                [deliveryIndex]: {
                  attributes: { $set: json.data.attributes },
                  relationships: { $set: json.data.relationships },
                },
              },
              included: {
                [shippingIndex]: { $set: shipping },
              },
            },
          });
        },
      });

    case IN_PROGRESS:
    case SHIP:
    case DELIVER:
    case INVOICE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          const delivery = json.included.find((inc) => inc.type === "delivery");
          const deliveryId = delivery.id;
          const index = prevState.all.items.findIndex((item) => item.id == deliveryId);

          return update(prevState, {
            all: {
              items: {
                [index]: {
                  attributes: { $set: delivery.attributes },
                },
              },
            },
          });
        },
      });

    case CREATE:
      return handle(state, action, {
        start: (prevState) => {
          return update(prevState, { loading: { $set: true } });
        },

        finish: (prevState) => {
          return update(prevState, { loading: { $set: false } });
        },

        success: (prevState) => {
          return update(prevState, {
            current: { delivery: { $set: json.data }, included: { $set: json.included } },
            all: { items: { $push: [json.data] }, included: { $push: json.included } },
          });
        },
      });

    case DESTROY:
      return handle(state, action, {
        success: (prevState) => {
          const deliveryId = action.meta.deliveryId;
          let deliveries = { ...prevState.all };
          deliveries.items = deliveries.items.filter((delivery) => delivery.id !== deliveryId);
          deliveries.included = deliveries.included.filter(
            (included) => included?.attributes?.deliveryId?.toString() !== deliveryId
          );
          return update(prevState, {
            all: { $set: deliveries },
          });
        },
      });

    case CREATE_DELIVERY_ITEM:
      return handle(state, action, {
        success: (prevState) => {
          return update(prevState, {
            all: { included: { $push: [json.data] } },
          });
        },
      });

    case UPDATE_DELIVERY_ITEM:
      return handle(state, action, {
        success: (prevState) => {
          const attributes = json.data.attributes;
          const deliveryItemId = json.data.id;
          const allIndex = prevState.all.included.findIndex((item) => item.type === "deliveryItem" && item.id === deliveryItemId);
          return update(prevState, {
            all: { included: { [allIndex]: { attributes: { $set: attributes } } } },
          });
        },
      });

    case DESTROY_DELIVERY_ITEM:
      return handle(state, action, {
        success: (prevState) => {
          const deliveryItemId = action.meta.deliveryItemId;
          const allIndex = prevState.all.included.findIndex((item) => item.type === "deliveryItem" && item.id === deliveryItemId);

          return update(prevState, {
            all: { included: { $splice: [[allIndex, 1]] } },
          });
        },
      });

    case UPDATE_BILL_OF_LADING:
      return handle(state, action, {
        success: (prevState) => {
          const billOfLadingId = json.data.id;
          const allIndex = prevState.all.included.findIndex((item) => item.type === "billOfLading" && item.id === billOfLadingId);

          return update(prevState, {
            all: { included: { [allIndex]: { attributes: { $set: json.data.attributes } } } },
          });
        },
      });
    default:
      return state;
  }
}

export default reducer;
