import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { selectCurrentUser } from "@ROM/Auth/selectors";
import { selectCurrentCompany } from "@ROM/Company/selectors";
import { canInProgressDelivery, canShipDelivery, canInvoiceDelivery, canDeliverDelivery } from "@ROM/DeliveryStatuses/permissions";
import { isCancelled as isOrderCancelled } from "@ROM/Orders/utils";
import DeliveryStatusBadge from "@ROM/Deliveries/components/common/DeliveryStatusBadge";
import { isPending, isInProgress, isShipped } from "../../utils";
import Deliver from "./ActionSections/Deliver";
import Ship from "./ActionSections/Ship";
import InProgress from "./ActionSections/InProgress";
import Invoice from "./ActionSections/Invoice";

const ActionSection = ({ order, delivery, dontShowStatus }) => {
  const currentUser = useSelector(selectCurrentUser);
  const currentCompany = useSelector(selectCurrentCompany);

  const [error, setError] = useState(false);

  const customerId = order.relationships.customer.data.id;

  const inProgress = isPending(delivery) && canInProgressDelivery(currentUser, customerId);
  const ship = isInProgress(delivery) && canShipDelivery(currentUser, customerId);
  const invoice = isShipped(delivery) && canInvoiceDelivery(currentUser, customerId);
  const deliver =
    isShipped(delivery) && canDeliverDelivery(currentUser, customerId) && currentCompany.attributes.deliveredStatusForDeliveries;

  const showActions = !isOrderCancelled(order) && (deliver || ship || inProgress);
  const showBadge = !(dontShowStatus || showActions);

  console.log(canInvoiceDelivery(currentUser, customerId))

  return (
    <>
      {showActions && (
        <>
          {inProgress && <InProgress delivery={delivery} setError={setError} />}
          {ship && <Ship delivery={delivery} setError={setError} />}
          {invoice && <Invoice delivery={delivery} setError={setError} />}
          {/* {deliver && <Deliver delivery={delivery} setError={setError} />} */}
          {error && <p className="text-danger m-0 mt-2">Something went wrong, please try again later</p>}
        </>
      )}
      {showBadge && <DeliveryStatusBadge status={delivery.attributes.status} className="ms-2" />}
    </>
  );
};

ActionSection.propTypes = {
  order: PropTypes.shape().isRequired,
  delivery: PropTypes.shape().isRequired,
  dontShowStatus: PropTypes.bool.isRequired,
};

export default ActionSection;
