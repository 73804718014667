import Config from "Config";
import { getApiClient } from "@ROM/App/ApiClient";
import { IN_PROGRESS, SHIP, INVOICE, DELIVER } from "./constants";

const API_URL = Config.API_FULL_URL;

const urls = {
  collection() {
    return "/delivery_statuses";
  },
};

const status = (name, attributes = {}) => {
  const client = getApiClient(API_URL);
  return client.post(`${urls.collection()}/${name}`, attributes);
};

export default {
  inProgress(attributes = {}) {
    return status(IN_PROGRESS, attributes);
  },

  ship(attributes = {}) {
    return status(SHIP, attributes);
  },

  invoice(attributes = {}) {
    return status(INVOICE, attributes);
  },

  deliver(attributes = {}) {
    return status(DELIVER, attributes);
  },
};
