import React, { useState } from "react";
import Form from "@ROM-ui/Form";
import Row from "@ROM-ui/Row";
import Col from "@ROM-ui/Col";
import PropTypes from "prop-types";
import Message from "@ROM-common/Message/index";
import { getErrorMessage } from "@ROM-utils/errors";
import Select from "@ROM-components/common/Select";

import { BILLING_TYPE, SHIPPING_TYPE } from "@ROM/Addresses/actions";

const options = [
  { value: BILLING_TYPE, label: "Billing" },
  { value: SHIPPING_TYPE, label: "Shipping" },
];

const findOption = (list, value) => list.find((item) => item.value === value);

const AddressForm = ({ address, countries, onUpdate, errors }) => {
  const countryOptions = countries.map((country) => ({ value: country.countryCode, label: country.name }));
  const [domestic, setDomestic] = useState(address.country === "US" || address.country === null);

  const valueChanged = (event) => {
    onUpdate({ [event.target.dataset.field]: event.target.value });
  };

  const switchToDomestic = () => {
    if (!domestic) {
      onUpdate({ country: "US" });
      setDomestic(true);
    }
  };

  const switchToInternational = () => {
    if (domestic) {
      onUpdate({ country: "" });
      setDomestic(false);
    }
  };

  const hasError = errors && errors.length > 0;
  return (
    <Form>
      {hasError && (
        <Row>
          <Col>
            <Message message={getErrorMessage(errors)} fluid type="danger" />
          </Col>
        </Row>
      )}
      {!address.type && (
        <Row className="mb-2">
          <Col>
            <Form.Label>Type</Form.Label>
            <Select
              id="address-form-type"
              placeholder="Type"
              options={options}
              onChange={(event) => onUpdate({ type: event.value })}
              value={findOption(options, address.type)}
            />
          </Col>
        </Row>
      )}
      <Row className="mb-2">
        <Col>
          <Form.Label>Company Name*</Form.Label>
          <Form.Control
            as="input"
            type="text"
            value={address.customerCompanyName || ""}
            data-field="customerCompanyName"
            id="address-new-customer-company-name"
            onChange={valueChanged}
            className={address.customerCompanyName === "" || (address.customerCompanyName === undefined && hasError) ? "border-danger not-outline" : ""}
          />
        </Col>
      </Row>

      <Row className="mb-2">
        <Col>
          <Form.Label id="address-form-modal-label">Primary Contact*</Form.Label>
          <Form.Control
            as="input"
            type="text"
            data-field="contact"
            value={address.contact || ""}
            onChange={valueChanged}
            id="address-new-contact"
            className={address.contact === "" || (address.contact === undefined && hasError) ? "border-danger not-outline" : ""}
          />
        </Col>
      </Row>

      <Row className="my-3">
        <Col>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button type="button" className={`nav-link ${domestic ? "active" : ""}`} onClick={switchToDomestic}>
                US
              </button>
            </li>
            <li className="nav-item">
              <button type="button" className={`nav-link ${domestic ? "" : "active"}`} onClick={switchToInternational}>
                International
              </button>
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col>
          <Form.Label>Address 1*</Form.Label>
          <Form.Control
            as="input"
            type="text"
            data-field="address1"
            value={address.address1 || ""}
            onChange={valueChanged}
            id="address-new-address-1"
            className={address.address1 === "" || (address.address1 === undefined && hasError) ? "border-danger not-outline" : ""}
          />
        </Col>
      </Row>

      <Row className="mb-2">
        <Col>
          <Form.Label>Address 2</Form.Label>
          <Form.Control
            as="input"
            type="text"
            data-field="address2"
            id="address-new-address-2"
            value={address.address2 || ""}
            onChange={valueChanged}
          />
        </Col>
      </Row>

      <Row className="mb-2">
        <Col xs={12} md={4}>
          <Form.Label>City*</Form.Label>
          <Form.Control
            as="input"
            type="text"
            data-field="city"
            value={address.city || ""}
            onChange={valueChanged}
            id="address-new-city"
            className={address.city === "" || (address.city === undefined && hasError) ? "border-danger not-outline" : ""}
          />
        </Col>
        <Col xs={6} md={4}>
          <Form.Label>{domestic ? "State*" : "State / Province / Region*"}</Form.Label>
          <Form.Control
            as="input"
            type="text"
            data-field="state"
            value={address.state || ""}
            onChange={valueChanged}
            id="address-new-state"
            className={address.state === "" || (address.state === undefined && hasError) ? "border-danger not-outline" : ""}
          />
        </Col>
        <Col xs={6} md={4}>
          <Form.Label>{domestic ? "Zip*" : "Zip / Postal Code*"}</Form.Label>
          <Form.Control
            as="input"
            type="text"
            data-field="zip"
            value={address.zip || ""}
            onChange={valueChanged}
            id="address-new-zip"
            className={address.zip === "" || (address.zip === undefined && hasError) ? "border-danger not-outline" : ""}
          />
        </Col>
      </Row>

      {!domestic && (
        <Row className="mb-2">
          <Col>
            <Form.Label>Country*</Form.Label>
            <Select
              id="address-new-country"
              placeholder="Select country"
              options={countryOptions}
              onChange={(event) => onUpdate({ country: event.value })}
              value={findOption(countryOptions, address.country)}
              isInvalid={!address.country}
            />
          </Col>
        </Row>
      )}

      <Row className="mb-2">
        <Col>
          <Form.Label>Phone</Form.Label>
          <Form.Control
            as="input"
            type="text"
            value={address.phone || ""}
            data-field="phone"
            id="address-new-phone"
            onChange={valueChanged}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Label>{`Email${address.type === "BillingAddress" ? "*" : ""}`}</Form.Label>
          <Form.Control
            as="input"
            type="text"
            data-field="email"
            id="address-new-email"
            value={address.email || ""}
            onChange={valueChanged}
            className={
              address.type === "BillingAddress" && (address.email === "" || (address.email === undefined && hasError))
                ? "border-danger not-outline"
                : ""
            }
          />
        </Col>
      </Row>
    </Form>
  );
};

AddressForm.propTypes = {
  address: PropTypes.shape().isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      countryCode: PropTypes.string,
    })
  ).isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape()),
  onUpdate: PropTypes.func.isRequired,
};

AddressForm.defaultProps = {
  errors: null,
};

export default AddressForm;
