import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import BillOfLadingForm from "@ROM/BillOfLadings/components/Form";
import { Redirect } from "react-router";
import { selectCurrentUser } from "@ROM/Auth/selectors";
import { find } from "@ROM/Orders/actions";
import { list } from "@ROM/Deliveries/actions";
import TitledHeader from "@ROM-components/common/TitledHeader";
import { selectCurrentBillOfLading } from "@ROM/BillOfLadings/selectors";
import { canCreateBillOfLadings } from "@ROM/BillOfLadings/permissions";
import { homeUrl, orderUrl } from "@ROM-utils/urlHelpers";

const New = ({ orderId, deliveryId }) => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const canCreate = canCreateBillOfLadings(currentUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBillOfLadingData = async () => {
      setLoading(true);
      await Promise.all([dispatch(find(orderId)), dispatch(list({ orderId }))]);
      setLoading(false);
    };
    if (canCreate) {
      fetchBillOfLadingData();
    }
  }, [dispatch, orderId]);

  const billOfLading = useSelector(selectCurrentBillOfLading);

  if (billOfLading) {
    window.location.href = `/orders/${orderId}/deliveries/${deliveryId}/bills-of-lading/${billOfLading.id}/edit`
  }

  if (!canCreate) {
    return <Redirect to={homeUrl()} />;
  }

  return (
    <>
      <TitledHeader title="Bill of Lading" backLinkTitle="Back to Order" backUrl={orderUrl(orderId)} />
      <BillOfLadingForm
        user={currentUser}
        orderId={orderId}
        deliveryId={deliveryId}
        currentBillOfLading={billOfLading}
        loading={loading}
        canEditAllParams
      />
    </>
  );
};

New.propTypes = {
  deliveryId: PropTypes.number.isRequired,
  orderId: PropTypes.number.isRequired,
};

export default New;
